import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/HopeAllotment/HopeAllotment/src/layouts/pageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Looks like this page wasn't found.`}</strong></p>
    <p>{`Use the menu above to see our most  up-to-date information, or select "Contact" to ask us a question!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABdiqgXEFT/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAMBAhESIv/aAAgBAQABBQJt6sV4UdnOkxl5Uf/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFlf//EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BuWn/xAAYEAADAQEAAAAAAAAAAAAAAAAAATERIf/aAAgBAQAGPwJNO071lRcMKf/EABsQAAICAwEAAAAAAAAAAAAAAAABESExYXGh/9oACAEBAAE/IbBC8DUDs4HJzEOtJk0ZzOyyvJ//2gAMAwEAAgADAAAAEPT/AP/EABgRAQADAQAAAAAAAAAAAAAAAAEAEUFR/9oACAEDAQE/EELvY9J//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERITH/2gAIAQIBAT8QUJcEk4f/xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMUFRYf/aAAgBAQABPxAcBLBRvmP0ZlRJtOg9nWVl5COIVVyYgtEAFzkt4PGs/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Plant",
          "title": "Plant",
          "src": "/static/57f366b592309780ae4738fd43690e0a/c08c5/plant.jpg",
          "srcSet": ["/static/57f366b592309780ae4738fd43690e0a/4ec73/plant.jpg 180w", "/static/57f366b592309780ae4738fd43690e0a/158ba/plant.jpg 360w", "/static/57f366b592309780ae4738fd43690e0a/c08c5/plant.jpg 640w"],
          "sizes": "(max-width: 640px) 100vw, 640px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      